import Dexie from 'dexie';

export class DexieService extends Dexie {
  settingsTable: any;
  blocksTable: any;

  constructor() {
    super('Acelinkv2');

    this.version(1).stores({
      blocks: '[name+block_checksum+page],name,block_checksum,page',
      offline_queue: '++id,endpoint,payload',
      modules: 'id,name,path,status',
      settings: '&key,value',
      user_accounts_permissions: 'id,name,properties_id,value,[name+properties_id]',
      user_accounts_preferences: 'preference,value'
    });

    this.version(2).stores({
      requests_settings: 'id,name,value',
      work_orders_settings: 'id,name,value'
    });

    this.version(3).stores({
      offline_queue: '++id,endpoint,&payload'
    });

    this.version(4).stores({
      scheduled_works_models_settings: 'id,name,value'
    });

    this.settingsTable = this.table('settings');
    this.blocksTable = this.table('blocks');
    this.on('populate', () => {
      this.settingsTable.add({key: 'user_id', value: '0' });
      this.settingsTable.add({key: 'user_name', value: '0' });
      this.settingsTable.add({key: 'user_username', value: '0' });
      this.settingsTable.add({key: 'user_phone', value: '0' });
      this.settingsTable.add({key: 'user_profile', value: '0' });
      this.settingsTable.add({key: 'user_last_session', value: '0' });
      this.settingsTable.add({key: 'user_skills', value: '0' });
      this.settingsTable.add({key: 'user_email', value: '0' });
      this.settingsTable.add({key: 'user_avatar', value: '0' });
      this.settingsTable.add({key: 'customer', value: '0' });
      this.settingsTable.add({key: 'access_token', value: '0' });
      this.settingsTable.add({key: 'push_token', value: '0' });
      this.settingsTable.add({key: 'expires_in', value: '0' });
      this.settingsTable.add({key: 'property', value: -1 });
      this.settingsTable.add({key: 'warehouse', value: '0' });
      this.settingsTable.add({key: 'initial', value: '0' });
      this.settingsTable.add({key: 'online', value: '1' });
    });
    this.open();
  }
}
