<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        {{ 'exit' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ username }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form autocomplete="off" [formGroup]="userForm">
    <ion-list>
      <ion-item>
        <ion-input formControlName="name" class="ion-text-end" type="text" label="{{ 'name' | translate }}"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input formControlName="email" class="ion-text-end" type="email" label="Email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input formControlName="password" class="ion-text-end" type="password" label="Password"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input formControlName="phone" class="ion-text-end" type="tel" label="{{ 'phone' | translate }}"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input readonly formControlName="userProfile" class="ion-text-end" label="{{ 'profile' | translate }}">{{ userProfile }}</ion-input>
      </ion-item>
      <ion-item>
        <ion-select label="{{ 'initial_page' | translate }}" formControlName="initial_page" placeholder="{{ 'choose_an_option' | translate }}" selectedText="{{ initialPage }}" (ionChange)="changeSelectedText($event)">
          <ion-select-option value="new_request" *ngIf="requestsAllowed && checkPermission('requests') && checkPermission('create_request')">{{ 'new_request' | translate }}</ion-select-option>
          <ion-select-option value="new_work_order" *ngIf="workOrdersAllowed && checkPermission('workorders') && checkPermission('create_work_order')">{{ 'new_work_order' | translate }}</ion-select-option>
          <ion-select-option value="active_work_orders" *ngIf="workOrdersAllowed && checkPermission('workorders')">{{ 'active_work_orders' | translate }}</ion-select-option>
          <ion-select-option value="calendar">{{ 'calendar' | translate }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-select multiple="true" formControlName="daily_report" placeholder="{{ 'choose_an_option' | translate }}" [compareWith]="compareDailyReportWith" label="{{ 'daily_report' | translate }}">
          <ion-select-option *ngFor="let property of properties" value="{{property.id}}">{{property.name}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">{{ 'last_session' | translate }}</ion-label>
        {{ userLastSession }}
      </ion-item>
      <ion-item lines="none" *ngIf="userSkills?.length">
        <ion-label aria-label="Skills" position="stacked">{{ 'skills' | translate }}</ion-label>
        <ion-chip outline color="primary" *ngFor="let skill of userSkills">
          <ion-label>{{ skill }}</ion-label>
        </ion-chip>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">{{ 'version' | translate }}</ion-label>
        {{ version }}
      </ion-item>
    </ion-list>
    <ion-button expand="full" [disabled]="!userForm.valid" (click)="onSubmit(userForm.value)" *ngIf="checkPermission('self_manage_user_information')">{{ 'apply' | translate}}</ion-button>
  </form>
</ion-content>