import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Property } from 'src/app/interfaces/Property';
import { ModulesService } from 'src/app/services/modules.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
  private unsubscribe: Subscription[] = [];
  private translate = inject(TranslateService);
  private alertController = inject(AlertController);
  private modalController = inject(ModalController);
  private userFormBuilder = inject(FormBuilder);

  username: string;
  userUsername: string;
  userEmail: string;
  userPhone: string;
  userProfile: string;
  userLastSession: string;
  dailyReport: string[];
  initialPage: string;
  userSkills: string[];
  userForm: FormGroup;
  properties: Property[];
  workOrdersAllowed = false;
  requestsAllowed = false;
  version = environment.version;

  permissions = ['self_manage_user_information','requests','create_request','workorders','create_work_order'];
  permissionsValues: any;

  constructor(
    private usersService: UsersService,
    private settingsService: SettingsService,
    private propertiesService: PropertiesService,
    private modulesService: ModulesService
  ) {
    this.userForm = this.userFormBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
      password: new FormControl(''),
      phone: new FormControl(''),
      userProfile: new FormControl(''),
      initial_page: new FormControl(''),
      daily_report: new FormControl('')
    });
  }

  ngOnInit() {
    this.settingsService.getSetting('property')
      .then( (property) => {
        this.usersService.getPermissions(Number(property), this.permissions)
          .then( (permissions) => {
            this.permissionsValues = permissions;
          });

        this.usersService.getPermissions(0, this.permissions)
          .then( (permissions) => {
            this.permissionsValues = this.permissionsValues.concat(permissions);
          });
      });

    this.modulesService.getLocalModules()
      .then( (modules) => {
          modules.forEach( (module) => {
            if (module.name === 'workorders') {
              this.workOrdersAllowed = true;
            } else if (module.name === 'requests') {
              this.requestsAllowed = true;
            }
          });
      });
  }

  ionViewWillEnter() {
    this.getUser();
  }

  onSubmit(values) {
    this.usersService.updateUser(values)
      .then( (status) => {
        if (status) {
          this.presentInfoAlert(this.translate.instant('user_updated_successfully'));
        }
      });
  }

  async presentInfoAlert(messageText: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('profile'),
      message: this.translate.instant(messageText),
      buttons: ['OK']
    });

    await alert.present();
  }

  getUser() {
    this.settingsService.getSetting('user_name')
      .then( (name) => {
        this.userForm.get('name').setValue(name);
      });

    this.settingsService.getSetting('user_username')
      .then( (username) => {
        this.userUsername = username;
      });

    this.settingsService.getSetting('user_email')
      .then( (email) => {
        this.userForm.get('email').setValue(email);
      });

    this.settingsService.getSetting('user_phone')
      .then( (phone) => {
        if (phone !== 'null') {
          this.userForm.get('phone').setValue(phone);
        }
      });

    this.settingsService.getPreference('landingpage')
      .then( (page) => {
        if (page) {
          this.userForm.get('initial_page').setValue(page);
          this.initialPage = this.translate.instant(page);
        }
      });

    this.settingsService.getPreference('report')
      .then( (report) => {
        if (report) {
          this.dailyReport = report.split(',');
          this.userForm.get('daily_report').setValue(this.dailyReport);
        }
      });

    const propertiesSubscr = this.propertiesService.getAllProperties()
      .subscribe( (properties) => {
        if (properties.status) {
          this.properties =  properties.data;
        }
      });
    this.unsubscribe.push(propertiesSubscr);

    this.settingsService.getSetting('user_profile')
      .then( (profile) => {
        this.userForm.get('userProfile').setValue(profile);
      });

    this.settingsService.getSetting('user_last_session')
      .then( (lastSession) => {
        this.userLastSession = lastSession;
      });

    this.settingsService.getSetting('user_skills')
      .then( (skills) => {
        this.userSkills = skills.split(',');
        this.userSkills = this.userSkills.filter( us => us !== 'null');
      });
  }

  checkPermission(permission: string) {
    if (this.permissionsValues) {
      return this.permissionsValues.filter(p => p.name === permission).length === 1;
    }
    return false;
  }

  dismiss() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
    this.modalController.dismiss();
  }

  changeSelectedText(page) {
    if (page.detail.value.length == 0) {
      this.initialPage = this.translate.instant('dashboard');
    } else if (page.detail.value.length > 0) {
      this.initialPage = this.translate.instant(page.detail.value);
    }
  }

  compareDailyReportWith(propertyId, selected: any[]) {
    if (Array.isArray(selected) ) {
      return selected.indexOf(propertyId) !== -1;
    }
    return Number(selected) === Number(propertyId);
  }
}
