<ion-app>
    <ion-header *ngIf="!loginPage">
        <ion-toolbar color="primary">
            <ion-img slot="start" src="../../assets/images/logo.png" alt="logo" style="width:128px;margin-left:16px;"></ion-img>
            <ion-button (click)="presentAlertsAlert()" slot="end" color="tertiary" class="alertsButton" aria-label="alerts">
                <ion-icon name="notifications-outline"></ion-icon> {{totalAlerts}}
            </ion-button>
            <ion-button (click)="presentPropertiesAlert()" slot="end" class="propertyButton" aria-label="properties">
                <ion-icon name="business-outline"></ion-icon>
            </ion-button>
            <ion-menu-toggle slot="end" (click)="openMenu('sidemenu')">
                <ion-avatar style="width:36px;height:36px;" class="ion-margin-end"><img [src]="userAvatar"/></ion-avatar>
            </ion-menu-toggle>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-margin-horizontal">
        <ion-menu side="end" contentId="main-content" *ngIf="!loginPage" swipeGesture="false">
            <ion-content *ngIf="sideMenuOpen">
                <div class="ion-text-center">
                    <ion-avatar class="custom-avatar">
                        <img [src]="userAvatar" alt="avatar"/>
                    </ion-avatar>
                    <p>{{ username }}</p>
                </div>
                <ion-list>
                    <ion-item lines="full">
                        <div class="ms-40">
                            <a class="card-toolbar-btn btn btn-sm radius-round" (click)="selectLang('pt')">
                                <img class="w-5" src="assets/images/flags/pt.svg">
                                <span class="badge text-white position-tr text-120 badge-sm rounded-pill border-1 badge-flag" *ngIf="lang === 'pt'">
                                    <i class="fas fa-check text-success badge-flag-icon"></i>
                                </span>
                            </a>
                            <a class="card-toolbar-btn btn btn-sm radius-round" (click)="selectLang('es')">
                                <img class="w-5" src="assets/images/flags/es.svg">
                                <span class="badge text-white position-tr text-120 badge-sm rounded-pill border-1 badge-flag" *ngIf="lang === 'es'">
                                    <i class="fas fa-check text-success badge-flag-icon"></i>
                                </span>
                            </a>
                            <a class="card-toolbar-btn btn btn-sm radius-round" (click)="selectLang('us')">
                                <img class="w-5" src="assets/images/flags/us.svg">
                                <span class="badge text-white position-tr text-120 badge-sm rounded-pill border-1 badge-flag" *ngIf="lang === 'us'">
                                    <i class="fas fa-check text-success badge-flag-icon"></i>
                                </span>
                            </a>
                        </div>
                    </ion-item>
                </ion-list>
                <ion-list>
                    <ion-item lines="none">
                        <a (click)="openModal()" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-user text-primary-m1 text-105 me-1"></i>
                            {{ 'profile' | translate}}
                        </a>
                    </ion-item>
                    <ion-item lines="none">
                        <a (click)="resync()" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-rotate text-primary-m1 text-105 me-1"></i>
                            {{ 'sync' | translate}}
                        </a>
                    </ion-item>
                    <ion-item lines="none">
                        <a (click)="logOut()" class="dropdown-item btn btn-outline-grey bgc-h-secondary-l3 btn-h-light-secondary btn-a-light-secondary" role="button">
                            <i class="fa-solid fa-power-off text-warning-d1 text-105 me-1"></i>
                            {{ 'logout' | translate}}
                        </a>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-content *ngIf="mainMenuOpen">
                <ion-list>
                    <ion-list-header>
                        <ion-label>Menu</ion-label>
                    </ion-list-header>
                    <ion-item lines="none">
                        <a (click)="scanBarcode()" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-qrcode text-primary-m1 text-105 me-1"></i>
                            {{ 'Scan' | translate }}
                        </a>
                    </ion-item>
                    <ion-item lines="none">
                        <a (click)="navigateStructure()" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-sitemap text-primary-m1 text-105 me-1"></i>
                            {{ 'structure' | translate }}
                        </a>
                    </ion-item>
                    <ion-item lines="none" *ngIf="requestsModule && checkPermission('requests')">
                        <a (click)="presentRequestsActionSheet($event)" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-pen-to-square text-primary-m1 text-105 me-1"></i>
                            {{ 'requests' | translate }}
                        </a>
                    </ion-item>
                    <ion-item lines="none" *ngIf="vehiclesModule && checkPermission('vehicles')">
                        <a (click)="navigateFleet()" class="mt-1 dropdown-item btn btn-outline-grey bgc-h-primary-l3 btn-h-light-primary btn-a-light-primary radius-0">
                            <i class="fa-solid fa-car text-primary-m1 text-105 me-1"></i>
                            {{ 'trips' | translate }}
                        </a>
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-content>
    <ion-footer *ngIf="!loginPage && this.enviroData.property > 0">
        <ion-row>
            <ion-tab-button size="large" (click)="navigateCalendar()" *ngIf="workOrdersModule && checkPermission('workorders')">
                <i class="fa-solid fa-calendar fa-xl" style="margin-top: 1.2rem !important;"></i>
                <ion-label style="margin-top: 10px;" class="ion-text-center">{{ 'calendar' | translate }}</ion-label>
            </ion-tab-button>
            <ion-tab-button size="large" (click)="presentWorkOrdersActionSheet($event)" *ngIf="workOrdersModule && checkPermission('workorders')">
                <i class="fa-solid fa-wrench fa-xl" style="margin-top: 1.2rem !important;"></i>
                <ion-label style="margin-top: 10px;" class="ion-text-center">{{ 'workorders' | translate }}</ion-label>
            </ion-tab-button>
            <ion-tab-button size="large" (click)="presentScheduledActionSheet()" *ngIf="workOrdersModule && checkPermission('workorders')">
                <i class="fa-solid fa-calendar fa-xl" style="margin-top: 1.2rem !important;"></i>
                <ion-label style="margin-top: 10px;" class="ion-text-center">{{ 'scheduled_workorders' | translate }}</ion-label>
            </ion-tab-button>
            <ion-tab-button size="large" (click)="openMenu('mainmenu')">
                <i class="fa-solid fa-bars fa-xl" style="margin-top: 1.2rem !important;"></i>
                <ion-label style="margin-top: 10px;">Menu</ion-label>
            </ion-tab-button>
        </ion-row>
    </ion-footer>
</ion-app>
<button type="button" class="ad2hs-prompt" #ad2hsprompt (click)="addToHomeScreen()">{{ 'install' | translate }}</button>
<div class="ios-prompt" (click)="hideIosInstall()" *ngIf="iosPrompt">
    <span style="color: rgb(187, 187, 187); float: right; margin-top: -14px; margin-right: -11px;">&times;</span>
    <img src="assets/images/AEL.svg" style="float: left; height: 80px; width: auto; margin-top: -8px; margin-right: 1rem;">
    <p style="margin-top: -3px; line-height: 1.3rem;">{{ 'install_webapp_ios_1' | translate }} <img src="assets/images/AFm.svg" style="display: inline-block; margin-top: 4px; margin-bottom: -4px; height: 20px; width: auto;"> {{ 'install_webapp_ios_2' | translate }}</p>
</div>